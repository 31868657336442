import React, { ReactElement } from "react";

interface FormErrorMessageProps {
  children: React.ReactNode;
}

const FormErrorMessage = ({ children }: FormErrorMessageProps) => {
  return <p className="text-sm text-red-400 mt-2">{children}</p>;
};

export default FormErrorMessage;
