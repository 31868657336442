import React from "react";
import toast from "react-hot-toast";
import {
  useUpdateUserMutation,
  useSessionTokenQuery,
  useCurrentUserQuery,
} from "../../features/user/userApiSlice";
import { HeadProps, Link, navigate } from "gatsby";
import { handleErrorResponse } from "../../app/apiSlice";

import { useRequireAuthentication } from "../../features/user/hooks";
import FormPageWrapper from "../../components/FormPageWrapper";
import Layout from "../../components/Layout";
import ButtonPrimary from "../../components/ButtonPrimary";
import ButtonSpinner from "../../components/ButtonSpinner";
import { SubmitHandler, useForm } from "react-hook-form";
import FormErrorMessage from "../../components/FormErrorMessage";
import Seo from "../../features/seo/Seo";
import * as Sentry from "@sentry/gatsby";

interface ChangeUsernameFormValues {
  name: string;
}

const ChangeUsername = () => {
  useRequireAuthentication();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<ChangeUsernameFormValues>();

  const { data: currentUser } = useCurrentUserQuery();
  const { data: sessionToken } = useSessionTokenQuery();
  const [updateUserTrigger, { isLoading }] = useUpdateUserMutation();

  const onSubmit: SubmitHandler<ChangeUsernameFormValues> = (values) => {
    updateUserTrigger({
      sessionToken,
      id: currentUser.data.uuid,
      payload: {
        attributes: {
          name: values.name,
        },
      },
    })
      .unwrap()
      .then((fulfilled) => {
        toast.success("Your username has successfully been changed.");
        navigate("/user/settings");
      })
      .catch((rejected) => {
        handleErrorResponse(rejected).forEach((m) => {
          const e = Error(m);
          e.name = "ChangeUsername: onSubmit";
          Sentry.captureException(e);
        });
      });
  };

  return (
    <Layout>
      <FormPageWrapper title="Change username">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-5">
            <label
              htmlFor="name"
              className="font-bold text-neutral-700 block mb-1"
            >
              New username
            </label>
            <input
              id="name"
              placeholder={currentUser?.data?.name}
              className={`border shadow p-3 w-full rounded text-neutral-700 ${
                errors.name ? "border-red-300" : "border-neutral-300"
              }`}
              {...register("name", {
                required: true,
                maxLength: 60,
              })}
            />
            {errors.name?.type === "required" && (
              <FormErrorMessage>The new username is required.</FormErrorMessage>
            )}
            {errors.name?.type === "maxLength" && (
              <FormErrorMessage>Can not exceed 60 characters.</FormErrorMessage>
            )}
          </div>
          <ButtonPrimary
            type="submit"
            size="20"
            disabled={!currentUser?.data?.uuid || !sessionToken || isLoading}
          >
            Submit
            <ButtonSpinner enabled={isLoading} />
          </ButtonPrimary>
          <Link
            to="/user/settings"
            className="text-red-700 underline hover:text-red-600 inline-block ml-8"
          >
            Cancel
          </Link>
        </form>
      </FormPageWrapper>
    </Layout>
  );
};

export default ChangeUsername;

export const Head = (props: HeadProps) => {
  return (
    <Seo
      title="Change username | Giancoli Answers"
      pathname={props.location.pathname}
    />
  );
};
